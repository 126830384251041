/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"
import {
  ContentPage,
  ContentPageHeader,
  CompactContentHeader,
  ContentMetadata,
  ContentMetadataItem,
  GenericArticle,
  TopicPills,
  ContentPageMinimal,
  TextOnlyHeader,
} from "../components/content-page"
import { PageRoot, ContentNode } from "../data/content"
import { BlueprintLogo } from "../images/pillars"
import { NumericDate } from "../data/date"
import { Join } from "../data/list"
import { useTranslation } from "react-i18next"
import {
  useLocalisedContentViewer,
  useLocalisedContent,
  useLocalisedContentArray,
} from "../components/featured"
import SEO from "../components/seo"
import { ArticleLanguageSelector } from "../components/content-page"
import { Link } from "../components/nav"
import { useAnalytics } from "../data/analytics"
import { useTextDirection, TextDirectionProvider } from "../i18n"
import { ActionData } from "../components/actions"
import { ShareData, SocialSharer } from "../components/share"
import { ruLinebreakRegex } from "../data/methods"

const Job: PageRoot<{
  node: ContentNode
}> = ({
  data: { node },
  pageContext: { locale, locales },
  location,
  location: { pathname },
}) => {
  const { t } = useTranslation()
  useLocalisedContentViewer(locale, locales)
  const localisedContent = useLocalisedContent(node, locale)
  const locationNode = node.frontmatter.region || node.frontmatter.country
  const { title: localisedLocation } = locationNode
    ? useLocalisedContent(locationNode)
    : { title: undefined }
  const dir = useTextDirection(locale)

  let shareData = undefined

  if (location?.href && localisedContent.title) {
    const shareMessage = localisedContent.title
    shareData = new ShareData(shareMessage, location?.href, shareMessage)
  }

  useEffect(() => {
    if (locale == "ru") {
      ruLinebreakRegex()
    }
  }, [locale])

  return (
    <TextDirectionProvider locale={locale}>
      <Box sx={{ textAlign: dir.defaultDir }}>
        <SEO
          path={pathname}
          lang={localisedContent.locale}
          title={localisedContent.title}
          description={
            localisedContent.shortAbstract || localisedContent.abstract
          }
          imageUrl={node.frontmatter.featuredImage?.publicURL}
          locales={locales}
        />
        <TextOnlyHeader
          standalone
          themeColor={node.fields.themeColor}
          typeName={node.fields.typeName}
          location={localisedLocation}
          title={localisedContent.title}
          locales={locales}
          noChips
        />

        <ContentPageMinimal
          {...localisedContent}
          content={node}
          abstract={localisedContent.abstract}
          coloredHeadings
          fontFamily={"body"}
          meta={
            <ContentMetadata color={node.fields.themeColor}>
              {locales?.length && (
                <ArticleLanguageSelector locale={locale} locales={locales} />
              )}
              <ContentMetadataItem label={t(`Date`)}>
                <NumericDate value={node.frontmatter.publishDate} />
              </ContentMetadataItem>
              {!!node.frontmatter.document && (
                <Link
                  to={
                    node === null ? " " : node.frontmatter.document.publicURL!
                  }
                  target="_blank"
                  linkType="download"
                >
                  <ContentMetadataItem label={t(`Download`) + " ↧"} />
                </Link>
              )}
              {shareData && (
                <Box id="social-sharer">
                  <SocialSharer
                    shareData={shareData}
                    type={node.frontmatter.pillar || node.fields.type}
                    sx={{ paddingTop: "15px", paddingBottom: "25px" }}
                  />
                </Box>
              )}
            </ContentMetadata>
          }
        />
      </Box>
    </TextDirectionProvider>
  )
}

export const pageQuery = graphql`
  query Job($id: String!) {
    node: markdownRemark(id: { eq: $id }) {
      ...ContentPageFragment
    }
  }
`

export default Job
